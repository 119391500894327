import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/blog-post.js";
import { Badge } from "theme-ui";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`About me`}</h1>
    <Badge mdxType="Badge">greetings</Badge>&nbsp;
    <Badge mdxType="Badge">about me</Badge>
    <p>{`Hi, my name is `}<inlineCode parentName="p">{`Gilles`}</inlineCode>{`. I'm proud to be a `}<inlineCode parentName="p">{`Belgian`}</inlineCode>{` 🍻 ! I'm working as a `}<inlineCode parentName="p">{`civil engineer`}</inlineCode>{`. In my spare time, I like to `}<inlineCode parentName="p">{`hack`}</inlineCode>{` some small projects together. These projects have always been related with programming. More recently I also started to play around with microcontrollers, smart home devices, etc. `}</p>
    <p>{`Last but not least, I'm also a member of the notorious hackersquad `}<inlineCode parentName="p">{`Eetietshekken`}</inlineCode>{` 😎   `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      